import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";

import RegistrationForm from "./registration-form";
import { Typography } from "@material-ui/core";

import { useIntl } from "react-intl";
import messages from '../../../i18n/messages';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: "100%",
  },
  content: {
    border: "1px solid #D6DBDE",
    borderRadius: "4px !important",
    marginBottom: `${spacing(4)}px  !important`,
  },
  summary: {
    marginLeft: spacing(1.5),
    marginRight: spacing(1.5),
  },
  details: {
    alignItems: "center",
    placeContent: "center",
    marginLeft: spacing(1.5),
    marginRight: spacing(1.5),
    marginTop: spacing(3)
  },
}));

const Registration = () => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    (<div className={classes.roots}>
      <Accordion
        defaultExpanded={false}
        elevation={0}
        className={classes.content}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.summary}
        >
          <Typography variant="body2">
            {intl.formatMessage(messages.addUser)}
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.details}>
          <RegistrationForm />
        </AccordionDetails>
      </Accordion>
    </div>)
  );
};

Registration.propTypes = {
  weldLogDetails: PropTypes.object.isRequired,
};

export default Registration;
